<template>
  <i>
    <svg width="100%" height="100%" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.25007 12.5C10.1496 12.5 12.5001 10.1495 12.5001 7.25001C12.5001 4.35051 10.1496 2 7.25007 2C4.35054 2 2 4.35051 2 7.25001C2 10.1495 4.35054 12.5 7.25007 12.5Z"
        stroke="currentColor"
        stroke-width="1.35"
        stroke-linecap="round"
        stroke-linejoin="round" />
      <path
        d="M10.9624 10.9625L13.9999 14"
        stroke="currentColor"
        stroke-width="1.35"
        stroke-linecap="round"
        stroke-linejoin="round" />
    </svg>
  </i>
</template>

<style scoped>
i {
  width: 24px;
  height: 24px;
  display: inline-flex;
}
</style>
